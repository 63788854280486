import api from "../httpClient.js";

export async function filterImages(filtros) {
  const response = await api.get(`/midias${filtros}`);
  return response.data.midia;
}

// Upload Image
export async function uploadImage(fd) {
  const response = await api.post("/midias/uploadImage", fd);
  return response;
}

export function deleteMidia(id) {
  return api.delete(`/midias/${id}`);
}
